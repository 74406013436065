<template>
  <div class="ShoppingCart">
    <div class="content">
      <h3>购物车</h3>
      <!-- {{orderList}} -->
      <el-table
        ref="multipleTable"
        :data="orderList"
        border
        style="width: 100%"
        v-loading="loading"
        v-if="!reload"
        :row-key="
          (row) => {
            return row.id;
          }
        "
        @selection-change="handleSelectionChange"
      >
        <el-table-column :reserve-selection="true" type="selection" width="55">
        </el-table-column>
        <el-table-column prop="skuCode" label="货品规格" width="180">
          <template slot-scope="scope">
            <div>{{ scope.row.productName }}</div>
            <div :class="[{ Offtheshelf: scope.row.publishStatus != 1 }]">
              {{ scope.row.skuCode | skuCodeInfo
              }}<span v-if="scope.row.publishStatus != 1">(商品已下架)</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="文件" width="180">
          <template slot-scope="scope">
            <div v-if="scope.row.sourceWorkId == ''">
              {{ scope.row.designFile }}
            </div>
            <div v-else>
              <el-button
                type="primary"
                size="mini"
                @click="previewFile(scope.row.designFile)"
                >预览</el-button
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="货品金额"> </el-table-column>
        <el-table-column prop="quantity" label="数量"> </el-table-column>
        <el-table-column prop="price" label="订单金额">
          <template slot-scope="scope">
            <div>{{ scope.row.price }}</div>
            <div>(运费:{{ scope.row.freight }})</div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
      <el-row>
        <el-col :span="20">共选择{{ selectNum }}件商品</el-col>
        <el-col :span="4">
          <el-button type="primary" @click="toPay">去付款</el-button>
          <el-button @click="deleteOrder">删除</el-button>
        </el-col>
      </el-row>
    </div>
    <preview-pic
      v-if="showPreview"
      :files="showFile"
      @close="
        () => {
          showPreview = false;
        }
      "
    ></preview-pic>
  </div>
</template>

<script>
import { searchShop, deleteShop } from '../../api/printonline'
import { queryPreview, printcartpage, printcartdelete } from '@/api/cubeclient'
import previewPic from '@/components/previewPic.vue'
export default {
  data () {
    return {
      orderList: [],
      selectNum: 0,
      loading: false,
      currentPage: 1,
      total: 0,
      pageSize: 10,
      reload: true,
      showPreview: false,
      showFile: []
    }
  },
  created () {
    this.init()
  },
  components: {
    previewPic
  },
  methods: {
    /**
  * 预览文件
  */
    async previewFile (file) {
      let filsList = file.split(",").map(item => {
        return item.substring(item.lastIndexOf("/") + 1);
      })
      let res = await queryPreview({
        "names": filsList,
        "type": "jpg"
      })

      if (res.data.length < 1) {
        this.$message.error('数据异常')
        return
      }
      this.showPreview = true
      this.showFile = res.data.map(item => {
        return `https://${item}`
      })
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.currentPage = 1
      this.search()
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.search()
    },
    init () {
      this.reload = true
      this.search()
    },
    async search () {
      try {
        this.loading = true
        const res = await printcartpage({
          // cartCode: 1,
          pageNo: this.currentPage,
          pageSize: this.pageSize
        })
        if (res.code == 200) {
          this.orderList = res.data.list
          this.total = res.data.total
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
        this.reload = false
      }
    },
    handleSelectionChange () {
      let num = 0
      this.$refs.multipleTable.selection.forEach(element => {
        num++
      })
      this.selectNum = num
    },
    toPay () {
      const productInfos = []
      let haveOff = false
      this.$refs.multipleTable.selection.forEach(element => {
        if (element.publishStatus != 1) {
          haveOff = true
        }

        productInfos.push(element)
      })
      if (haveOff) {
        this.$message({
          message: '已下架商品不可购买',
          type: 'warning'
        })
        return
      }
      if (productInfos.length == 0) {
        this.$message({
          message: '未选择商品',
          type: 'warning'
        })
        return
      }
      this.$router.push({ name: 'OrderItemList', params: { productInfos } })
    },
    deleteOrder () {
      const productIds = []
      this.$refs.multipleTable.selection.forEach(element => {
        //  console.log(Number(element.id))
        productIds.push(element.id)
      })
      if (productIds.length == 0) {
        this.$message({
          message: '未选择商品',
          type: 'warning'
        })
        return
      }
      this.$confirm('确定删除购物车记录', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await printcartdelete(productIds)
        this.currentPage = 1
        this.init()
        this.$message.success('删除成功')
        this.selectNum = 0
      })
    }
  },
  filters: {
    skuCodeInfo (value) {
      if (!value) {
        return ''
      }
      let result = ''
      const skuCodes = JSON.parse(value)
      skuCodes.forEach(skuCode => {
        for (const key in skuCode) {
          result = result + `${key}:${skuCode[key]}  `
        }
      })
      return result
    }
  }
}
</script>

<style lang="scss" scoped>
.Offtheshelf {
  color: red;
}
.ShoppingCart {
  width: 100%;
  display: flex;
  justify-content: center;
  .content {
    width: 1200px;
    padding-top: 20px;
  }
}
</style>
