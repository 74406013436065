import Request from '@/lib/Request'
// const http = new Request('https://cubewebapi.51ylk.com',3000)
const http = new Request(process.env.VUE_APP_URL)

// 商品分类信息
export const selectAllTaxonomy = params => {
  return http.get('/cube_web_api/taxonomy/selectAllTaxonomy', params)
}
// 商品分类信息
export const getChildrenById = params => {
  return http.get('/cube_web_api/product/menue/getChildrenById', params)
}
// 查询分页
export const listSpuForWeb = params => {
  return http.post('/cube_web_api/product/spuinfo/listSpuForWeb', params)
}

// 查询分页
export const queryPage = params => {

  return http.post('/cube_web_api/product/spuinfo/queryPage', params)
  // return http.post('http://192.168.1.80:8090/cube_web_api/product/spuinfo/queryPage', params)
}

// 查询商品详情信息
export const getspuitem = params => {
  return http.get('/cube_web_api/product/spuinfo/getspuitem', params)
}
// 组装商品金额（立即下单）
export const assemblyOrder = params => {
  return http.post('/cube_web_api/order/assemblyOrder', params)
}
// 组装商品金额（立即下单）
export const placeOrder = params => {
  return http.post('/cube_web_api/order/placeOrder', params)
}

// 添加需求订单
export const demandOrderSave = params => {
  return http.post('/cube_web_api/order/demandorder/save', params)
}

// 添加需求订单
export const demandorderpageList = params => {
  return http.post('/cube_web_api/order/demandorder/pageList', params)
}

// 个人需求订单
export const pageListByPersonalUser = params => {
  return http.post('/cube_web_api/order/demandorder/pageListByPersonalUser', params)
}
// 运营需求订单
export const pageListByOperationalRole = params => {
  return http.post('/cube_web_api/order/demandorder/pageListByOperationalRole', params)
}

// 支付链接
export const orderPayment = params => {
  return http.post('/cube_web_api/order/orderPayment', params)
}
// 添加购物车
export const addshop = params => {
  return http.post('/cube_web_api/order/cart/add', params)
}
// 查询购物车
export const searchShop = params => {
  return http.post('/cube_web_api/order/cart/search', params)
}
// 查询购物车
export const deleteShop = params => {
  return http.post('/cube_web_api/order/cart/delete', params)
}
// 搜索订单详情
export const searchOrderDetail = params => {
  return http.post('/cube_web_api/order/searchOrderDetail', params)
}
// 需求订单-修改状态
export const demandorderUpdateStatus = params => {
  return http.post('/cube_web_api/order/demandorder/updateStatus', params)
}
// 印刷订单-查询分页
export const searchPrintOrder = params => {
  return http.post('/cube_web_api/order/searchOrder', params)
}
// 印刷订单-查询分页
export const stateList = params => {
  return http.post('/cube_web_api/order/demandorder/stateList', params)
}
// 印刷订单-查询分页
export const orderState = params => {
  return http.post('/cube_web_api/order/demandorder/orderState', params)
}
// 印刷订单-查询分页
export const saveBatch = params => {
  return http.post('/cube_web_api/psdjson/saveBatch', params)
}
// 运营需求单-确认下单-[类似加入购物车的逻辑]
export const operationCartAdd = params => {
  return http.post('/cube_web_api/order/cart/operationCart/add', params)
}

// 运营完善需求单后-查看详情
export const queryBookingInfoFromCartById = params => {
  return http.post('/cube_web_api/order/demandorder/queryBookingInfoFromCartById', params)
}

// 运营完善需求单后-查看详情
export const personalUserCancelOrder = params => {
  return http.post('/cube_web_api/order/demandorder/personalUserCancelOrder', params)
}

// 运营完善需求单后-查看详情
export const update = params => {
  return http.post('/cube_web_api/order/cart/operationCart/update', params)
}
